import Hero from "./Hero"
import Blog from "./Blog"

export default function Index() {
    return (
        <>
            <Hero />
            <Blog />
        </>
    )
}