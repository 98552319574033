import Hero from "./Hero";
import WhyRangmashal from "./WhyRangmashal";

export default function Index() {
    return (
        <>
            <Hero />
            <WhyRangmashal/>
        </>
    )
}